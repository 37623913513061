export default {
  proxy_path: "https://ov9dqb1q4m.execute-api.eu-west-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://6pyj0oxuea.execute-api.eu-west-1.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "6",
  crm_base_url: "https://crm.macquarie.forwoodsafety.com",
  forwood_id_url: "https://id.macquarie.forwoodsafety.com?redirect_uri=https://mapreport.macquarie.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.macquarie.forwoodsafety.com",
  Auth: {
    userPoolId: "eu-west-1_TmKudpTMn",
    userPoolWebClientId: "55a1m3t1uo9vnersdql5gjn3ub",
    cookieStorage: {
      domain: ".macquarie.forwoodsafety.com",
      secure: true
    }
  }
};
